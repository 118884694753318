import React from "react";
import application_image from "../../../assets/img/components/process/process.jpg";
import ApplicationProcess from "../../shared/applicationProcess";

export default function Process() {
  return (
    <section id="process">
      <ApplicationProcess
        steps={[
          "Hinterlege deine Kontaktdaten.", 
          "Werde zum Start der nächsten Bewerbungsphase benachrichtigt."
        ]}
        title="Bleibe auf dem Laufenden."
        buttonIconColor="yellow"
        buttontext="Jetzt Kontakt aufnehmen"
        buttonGridTemplateAreas={`'. grid2 .''. . text'`}
        buttonTextBackgroundColor="blue"
        buttonTextColor="white"
        image={application_image}
        link="https://utum.typeform.com/to/Hn4KEeuM"
      />
    </section>
  );
}
